$primary: rgb(63, 81, 181);
@import "~bootstrap/scss/bootstrap";

$textColor: #fff;
.btn-primary {
    @include button-variant($primary, $primary, $textColor);
}

body {
  overflow: hidden;
}

iframe {
  overflow: hidden;
}
